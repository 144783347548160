import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'Navisworks VR Plugin | Prospect by IrisVR'
const description = `Bring Navisworks models into VR instantly with Prospect by IrisVR. True to scale clash detection, coordination, and QA/QC meetings with BIM in VR.`
const url = `${getWindowLocationOrigin()}/navisworks`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Quest.jpg.png'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
