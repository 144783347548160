import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../../components/Shared'
import IntegrationsTemplate from '../../components/Integrations'
import helmetProps from '../../components/Integrations/Navisworks/helmetProps'
import { sanitizedCopy, sanitizedExampleFeatureData } from './utils'

export const query = graphql`
  {
    irisLogo: file(
      relativeDirectory: { eq: "integrations" }
      name: { eq: "1_IrisLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    navisworksIcon: file(
      relativeDirectory: { eq: "integrations/Navisworks" }
      name: { eq: "NavisworksLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    navisworksExportModel: file(
      relativeDirectory: { eq: "integrations/Navisworks" }
      name: { eq: "1.1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 1058, maxHeight: 928, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    navisworksVRIdea: file(
      relativeDirectory: { eq: "integrations/Navisworks" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 744, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    navisworksBIM: file(
      relativeDirectory: { eq: "integrations/Navisworks" }
      name: { eq: "3.1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 560, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    navisworksGeometry: file(
      relativeDirectory: { eq: "integrations/Navisworks" }
      name: { eq: "3.2" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 982, maxHeight: 592, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    navisworksViewpoints: file(
      relativeDirectory: { eq: "integrations/Navisworks" }
      name: { eq: "3.3" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 560, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    navisworksPlugin: file(
      relativeDirectory: { eq: "integrations/Navisworks" }
      name: { eq: "3.4" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 984, maxHeight: 592, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(props => {
  const data = props.data
  const images = {
    logos: {
      iris: data.irisLogo,
      integrationCompanyIcon: data.navisworksIcon,
    },
    ideaMockups: {
      exportModel: data.navisworksExportModel,
      vrIdea: data.navisworksVRIdea,
    },
  }

  const header = `True-to-scale coordination meetings with anyone in the world`
  const subHeader = `The Prospect plugin for Navisworks`
  const integrationExplanationHeader = `Enhance BIM and VDC workflows`
  const integrationExplanation = `Prospect makes it easy to host model coordination and QA/QC meetings in a true-to-scale environment. Quickly catch clashes, spot issues, and solve problems with Prospect's built-in tools before they make it onto the job site.`
  const sectionLinksHeader = `Prospect directly imports Navisworks files into VR at the click of a button, with:`
  const finalCopy = `Review Navisworks models in 1:1 scale today.`
  const copy = {
    header,
    subHeader,
    integrationExplanationHeader,
    integrationExplanation,
    sectionLinksHeader,
    finalCopy,
  }

  const exampleFeatureData = {
    bim: {
      image: data.navisworksBIM,
      title: 'Element-level BIM metadata',
      description: `Navisworks BIM metadata properties import directly into Prospect. Pull important model information during coordination meetings without referencing the source file.`,
    },
    geometry: {
      image: data.navisworksGeometry,
      title: 'Linked geometry',
      description: `Any and all linked geometry from Navisworks files goes into VR, including geometry from:`,
      descriptionList: [
        'SketchUp',
        'Revit',
        'Rhino',
        '3DS Max',
        'FBX, IFC, OBJ',
        'SolidWorks',
        'Inventor',
        'Catia',
      ],
    },
    viewpoints: {
      image: data.navisworksViewpoints,
      title: 'Viewpoints',
      description: `Saved Navisworks Viewpoints are imported into Prospect for focused model review sessions. Bring clashes into VR by exporting Clash Reports as Viewpoints in Navisworks.`,
    },
    plugin: {
      image: data.navisworksPlugin,
      title: 'Powerful, customizable plugin',
      description: `Our native Navisworks plugin helps curate the precise VR experience needed with each import to VR. Select exact views and BIM metadata to bring into Prospect to customize every VR experience.`,
    },
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <IntegrationsTemplate
        images={images}
        copy={sanitizedCopy(copy)}
        exampleFeatureData={sanitizedExampleFeatureData(exampleFeatureData)}
      />
    </>
  )
})
